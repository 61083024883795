module.exports ={
  "propertyService": "https://6pppmwcpv1.execute-api.us-east-1.amazonaws.com/jon",
  "domusoApiService": "https://77ctsh3qzl.execute-api.us-east-1.amazonaws.com/jon",
  "appService": "https://domusograilsweb-jon.devmuso.com",
  "reservationService": "https://bzz7ok513d.execute-api.us-east-1.amazonaws.com/jon",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://xy3jp7fzmc.execute-api.us-east-1.amazonaws.com/jon",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-jon.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-jon.devmuso.com",
  "maintenanceUrl": "https://xy3jp7fzmc.execute-api.us-east-1.amazonaws.com/jon/maintenance",
  "env": "jon"
}